import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Hepatitis C";

// Download link: http://www.fbrennangastro.com.au/downloads/gesahepatitisc(1).pdf - kids
// Download link: http://www.fbrennangastro.com.au/downloads/gesahepatitisc.pdf - adults

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[
				title,
				"Causes of hep C",
				"Hep C",
				"liver virus",
				"hepatitis without immunisation",
				"hepatitis no vaccine",
			]}
			type="article"
			description="Hepatitis type C information - symptoms, at risk groups, lack of immunisation options"
		/>
		<H1>{title}</H1>
		<Par>
			Hepatitis C is a viral infection that causes liver inflammation and can lead to cirrhosis, liver failure or liver
			cancer. Hepatitis C is thought to be the most common cause of chronic hepatitis and affects about 1% of the
			Australian community.
		</Par>
		<Par>
			People acquire hepatitis C by contact with infected blood. It is commonly spread through sharing unsterile
			needles, syringes and other injecting drug equipment. Some people contracted hepatitis C through blood transfusion
			or receiving infected blood products before they were effectively screened in the 1990s.
		</Par>
		<Par>
			Hepatitis C is chronic when the body cannot get rid of the hepatitis C virus. Although some people clear the virus
			from their bodies in a few months, many hepatitis C infections become chronic.
		</Par>
		<Par>
			Without treatment, chronic hepatitis C can cause scarring of the liver or cirrhosis, liver cancer and liver
			failure. There is currently no vaccine to prevent hepatitis C infection.
		</Par>
	</Blog>
);
